<template lang="pug">
    .py-10.px-8
        .max-w-960.m-auto.mt-6.px-8
            h1(class="mt-2 mb-8 text-3xl text-center") Account Settings

            h3.mb-4 Account Details
            .grid.grid-cols-3.gap-4
                .col-span-1
                    v-text-field(
                        label="First name"
                        hide-details="auto"
                        :rules="rules"
                        filled dense
                    )
                .col-span-1
                    v-text-field(
                        label="Last name"
                        hide-details="auto"
                        :rules="rules"
                        filled dense
                    )

            h3.mt-12.mb-4 Billing Details
            .grid.grid-cols-3.gap-4.mb-4
                .col-span-1
                    v-text-field(
                        label="Billing Entitly Name"
                        :rules="[required]"
                        hide-details="auto"
                        filled dense
                    )
                .col-span-1
                    v-text-field(
                        label="Billing Entitly ABN"
                        hide-details="auto"
                        filled dense
                    )
                .col-span-1
                    v-text-field(
                        label="Billing Contact Name"
                        hide-details="auto"
                        filled dense
                    )
                .col-span-1
                    v-text-field(
                        label="Billing Contact Name"
                        hide-details="auto"
                        filled dense
                    )
                .col-span-1
                    PhoneNumber
                .col-span-1
                    v-text-field(
                        label="Billing Email"
                        hide-details="auto"
                        filled dense
                        type="email"
                    )

            .grid.grid-cols-2.gap-4.mt-12
                .col-span-1
                    h3.mb-4 Billing Address
                    Address


            h3.mt-12.mb-4 Shipping Details
            .grid.grid-cols-2.gap-4
                .col-span-1
                    v-checkbox(
                        hide-details="true"
                        color="white"
                        :ripple="false"
                        :rules="rules"
                        class="mt-0 pt-0 mb-4")
                        template(slot="label")
                            span(class="text-grey-3")
                                | Shipping address is the same as the billing address
                    Address

            .mt-8.flex.justify-center.flex-col
                v-btn(
                    class="text-white m-auto w-40"
                    depressed
                    :disabled="loading"
                    color="primary"
                    raised
                    large
                    type="button"
                    @click.stop="createOrUpdateTenant"
                )
                    | Save
</template>

<script>
import Address from '@/components/shared/Address.vue'
import PhoneNumber from '@/components/shared/PhoneNumber.vue'

export default {
    name: "AccountSettings",
    components: { Address, PhoneNumber },
    setup() {
        return {};
    },
};
</script>
